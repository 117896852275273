body {
  font-family: "Open Sans", sans-serif;
  background: #eff1f5;
  overflow-x: hidden;
}

.whatwedo {
  background: #03254c;
  padding: 30px;
}
.logspan {
  padding-top: 40px;
  margin-left: -30px;
}

.navbarside {
  padding: 0;
  top: 0px;
}

.navbarside ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}

.navbarside li {
  position: relative;
}

.navbarside > ul > li {
  white-space: nowrap;
  padding: 8px 12px;
}

.navbarside a,
.navbarside a:focus {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: space-between;
  padding: 0 3px;
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
  white-space: nowrap;
  transition: 0.3s;
}

.navbarside a i,
.navbarside a:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}

.navbarside > ul > li > a:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: -6px;
  left: 0;
  background-color: #16977f;
  visibility: hidden;
  width: 0px;
  transition: all 0.3s ease-in-out 0s;
}

.navbarside a:hover:before,
.navbarside li:hover > a:before,
.navbarside .active:before {
  visibility: visible;
  width: 100%;
}

.navbarside a:hover,
.navbarside .active,
.navbarside .active:focus,
.navbarside li:hover > a {
  color: #ffffff;
}

.getstarted,
.getstarted:focus {
  padding: 8px 25px;

  background: transparent;
  color: #fff;
  border: 2px solid #fff;
}

.navbarside > ul > li > .getstarted:before {
  visibility: hidden;
}

/**
* Mobile Navigation 
*/
.mobile-nav-toggle {
  color: #fff;
  font-size: 28px;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: 0.5s;
}

@media (max-width: 991px) {
  .mobile-nav-toggle {
    display: block;
  }

  .navbarside ul {
    display: none;
  }
}

.navbarside-mobile {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(4, 7, 9, 0.9);
  transition: 0.3s;
  z-index: 999;
}

.navbarside-mobile .mobile-nav-toggle {
  position: absolute;
  top: 15px;
  right: 15px;
}

.navbarside-mobile ul {
  display: block;
  position: absolute;
  top: 55px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  padding: 10px 0;
  border-radius: 10px;
  background-color: #fff;
  overflow-y: auto;
  transition: 0.3s;
}

.navbarside-mobile a,
.navbar-mobile a:focus {
  padding: 10px 20px;
  font-size: 15px;
  color: #15222b;
}

.navbar-mobile a:hover,
.navbar-mobile .active,
.navbar-mobile li:hover > a {
  color: #16977f;
}

.navbar-mobile .getstarted,
.navbar-mobile .getstarted:focus {
  margin: 15px;
}

.navbar-mobile .dropdown ul {
  position: static;
  display: none;
  margin: 10px 20px;
  padding: 10px 0;
  z-index: 99;
  opacity: 1;
  visibility: visible;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}

.navbar-mobile .dropdown ul li {
  min-width: 200px;
}

.navbar-mobile .dropdown ul a {
  padding: 10px 20px;
}

.navbar-mobile .dropdown ul a i {
  font-size: 12px;
}

.navbar-mobile .dropdown ul a:hover,
.navbar-mobile .dropdown ul .active:hover,
.navbar-mobile .dropdown ul li:hover > a {
  color: #16977f;
}

.navbar-mobile .dropdown > .dropdown-active {
  display: block;
}

.logosize {
  height: 90px;
  width: 200px;
}
.logosizet {
  height: 50px;
  width: 200px;
}
.rowsize {
  width: 100%;
}
.childbusimge {
  margin-top: -20px;
  height: 100vh;
  width: 50vw;
}
.logofooter {
  width: 200px;
}
.logofoo {
  font-size: 28px;
  font-weight: 800;
  margin-top: -20px;
  padding: 0;
  line-height: 2;
  font-weight: 700;
  letter-spacing: 2px;
  color: #000;
}

.navheader {
  width: 100%;
  background: rgba(21, 34, 43, 0.85);
  padding: 10px 0;
  border-radius: 15px;
}
.nav-link {
  color: #4e5153;
  font-weight: 600;
}

.rowse {
  background-image: url("./components/images/test.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}
.visit {
  padding: 3px 8px;
  background: #03254c;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  width: 40px;
}
.titlecates {
  padding-left: 50px;
  color: #03254c;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  text-shadow: 0 1px 1px #fff;
}
.modalfinal {
  margin-left: 37%;
  margin-top: -30px;
}
.modal {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0;
}
.modal-dialog {
  max-width: 800px;
  right: 0;
}
.sekama {
  padding: 15% 10%;
}

.overlayside {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background: linear-gradient(80deg, #06152b -4.57%, rgba(0, 0, 0, 0) 89.44%);

  opacity: 6;
}
.Welcome {
  color: #ffffff;
  font-size: 25px;
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  padding-right: 40%;
}
.newarr {
  color: #ffffff;
  font-size: 35px;
  font-weight: 900;
  padding-bottom: 10px;
}

.contact {
  background: #fff;
}
.footerdown {
  position: absolute;
  width: 100%;
  margin-bottom: 0;
  color: #ffffff;
  background: #03254c;
}
.navbarside.ul {
  color: #ffffff;
}

.form-controle {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #0d0d0e;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.form-controlem {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  height: 78px;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #0d0d0e;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.form-control {
  display: block;
  width: 80%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #35080a;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.closeModale {
  border: 50px;
  background: #03254c;
  height: 30px;
  width: 30px;
  color: #fff;
  margin-top: 4px;
  float: right;
  font-size: large;
  cursor: pointer;
  font-size: 1.2em;
  text-align: center;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.2);
  border-radius: 15px 15px 15px 15px;
}

.logosize {
  height: 80px;
  width: 100px;
}

.titleabout {
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 50px;
  color: #03254c;
  padding-bottom: 15px;
}
.titleabouts {
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 50px;
  color: #fff;
  padding-bottom: 20px;
}
.fore {
  margin-top: -40px;
}
.imgfol {
  height: 370px;
}
.shopnoww {
  color: #fff;
}

@media (max-width: 575px) {
  .Welcome {
    color: #ffffff;
    font-size: 25px;
    font-family: "Poppins", Arial, Helvetica, sans-serif;
    padding-right: 20px;
  }
  .fore {
    margin-top: -40px;
    margin-left: -100px;
  }
  .shopnoww {
    color: #fff;
  }
  .sekama {
    padding: 40% 10%;
  }
  .logspan {
    padding-top: 5px;
    margin-left: -30px;
    font-size: 20px;
  }
  .photoho {
    position: relative;
    margin-left: 5px;
    width: 100%;
  }
  .contact .php-email-form {
    box-shadow: 0 0 30px rgba(214, 215, 216, 0.6);
    padding: 30px;
  }
  .photoho {
    width: 100%;
  }
  .titlecates {
    padding-left: 120px;
    color: #03254c;
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    text-shadow: 0 1px 1px #fff;
  }
  .form-control {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #35080a;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0.375rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  .modalfinal {
    margin-left: 5px;
    margin-top: 5px;
  }
}
